.mobileMenu {
  backdrop-filter: blur(8px);
  background-color: black;
}

.bgMain {
  background-color: black;
}

@-webkit-keyframes fadeinout {
  0% { opacity: 0.6; transform: translateY(20px); }
  50% { opacity: 1; transform: translateY(0px); }
}

@keyframes fadeinout {
  0% { opacity: 0.6; transform: translateY(20px); }
  50% { opacity: 1; transform: translateY(0px); }
}

.elementFadeUp {
  -webkit-animation: fadeinout 0.8s linear forwards;
  animation: fadeinout 0.8s linear forwards;
}

@-webkit-keyframes fadeInDown {
  0% { opacity: 0.6; transform: translateY(-20px); }
  50% { opacity: 1; transform: translateY(0px); }
}

@keyframes fadeInDown {
  0% { opacity: 0.6; transform: translateY(-20px); }
  50% { opacity: 1; transform: translateY(0px); }
}

.elementFadeDown {
  -webkit-animation: fadeInDown 0.5s linear forwards;
  animation: fadeInDown 0.5s linear forwards;
}

.infoContainer {
  background: #feeeed0d;
  border-radius: 8px;
  border: 1px solid #ffffff1c;
}

.nice-scroll::-webkit-scrollbar {
  width: 3px;
}

.nice-scroll::-webkit-scrollbar-track {
  background: #464444;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb:hover {
  background: #464444;
}