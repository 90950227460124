@-webkit-keyframes fadeInDown {
	0% { opacity: 0.6; transform: translateY(-10px); }
	50% { opacity: 1; transform: translateY(0px); }
}

@keyframes fadeInDown {
	0% { opacity: 0.6; transform: translateY(-10px); }
	50% { opacity: 1; transform: translateY(0px); }
}

.filterDown {
	-webkit-animation: fadeInDown 0.3s linear forwards;
	animation: fadeInDown 0.3s linear forwards;
}

.detailInfo {
	width: 30%;
	margin: auto;
	overflow-y: scroll;
	height: 80%;
}